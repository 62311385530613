import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
	DecimalToFixed,
	FormatAmount,
	TrimTime,
	FormatTransactionNumber,
} from "../../utils/helper";
import Barcode from "react-barcode";
import GenerateQRCode from "../QRCode/GenerateQRCode";

const tdStyle = { direction: "ltr" };

function Header(props) {
	let headerText;
	let headerSubText;

	headerText = <b>Receipt / إيصال </b>;

	if (props.data.serviceId == 57 || props.data.serviceId == 59) {
		headerSubText = <b>Card Create / إنشاء بطاقة</b>;
	} else if (props.data.serviceId == 58 || props.data.serviceId == 60) {
		headerSubText = <b>Card Cash-Out / سحب من بطاقة</b>;
	}
	else if (props.data.serviceId == 61) {
		headerSubText = <b>Card Top-Up / شحن البطاقة</b>;
	}

	let ReprintCode = <td width='35%' align='right' valign='middle'></td>;

	if (props.iscopy) {
		ReprintCode = (
			<td width='35%' align='right' valign='middle'>
				<p>
					<span className='w-25 text-start'>Reprint:</span>
					<span className='w-50 text-center'> {props.data.currentDate}</span>
					<span className='w-25 text-end'>:إعادة طباعة</span>
				</p>
			</td>
		);
	}

	return (
		<table width='100%' className='header'>
			<tbody>
				<tr>
					<td width='35%' align='left'>
						<p className='mb-0'>
							<span className='w-75 d-inlineblock valign-middle'>
								<Barcode
									value={props.data.transactionNumber}
									height={25}
									width={1}
									fontSize={10}
									margin={5}
								/>
							</span>
						</p>
					</td>
					<td align='center' valign='top'>
						<h4 style={{ marginTop: "10px" }}>{headerText}</h4>
						<h4 style={{ fontSize: "12px" }}>{headerSubText}</h4>
					</td>
					{ReprintCode}
				</tr>
				<tr>
					<td width='35%' align='left'>
						<p>
							<span className='w-25 text-start'>TRN No:</span>
							<span className='w-50 text-center'>
								<b> {props.data.transactionNumber} </b>
							</span>
							<span className='w-25 text-end'>:العدد</span>
						</p>
						<p>
							<span className='w-25 text-start'>Date:</span>
							<span className='w-50 text-center'>
								<b> {props.data.transactionDate} </b>
							</span>
							<span className='w-25 text-end'>:التاريخ</span>
						</p>
					</td>
					<td align='center' valign='top'></td>
					<td width='35%' align='right' valign='bottom'>
						<p>
							<span className='w-25 text-start'>Branch:</span>
							<span className='w-50 text-center'>
								<b> {props.data.branchName} </b>
							</span>
							<span className='w-25 text-end'>:الفرع</span>
						</p>
						<p>
							<span className='w-25 text-start'>Phone:</span>
							<span className='w-50 text-center'>
								<b> {props.data.branchPhone} </b>
							</span>
							<span className='w-25 text-end'>:هاتف</span>
						</p>
					</td>
				</tr>
			</tbody>
		</table>
	);
}

function ClientInfo(props) {
	let senderEnTitle = "Account";//"Client Information";
	let senderArTitle = "حساب";//"معلومات عميل";

	return (
		<tr>
			<td width='100%' className='p-0'>
				<h3>
					<span className='w-50'>{senderEnTitle}</span>
					<span className='w-50 text-right'>{senderArTitle}</span>
				</h3>
				<p>
					<span className='w-25 text-start'>Account:</span>
					<span className='w-50 text-center'>
						<b> {props.data.customerAccount} </b>
					</span>
					<span className='w-25 text-end'>:حساب</span>
				</p>
			</td>
		</tr>
	);
}
function CustomerInfo(props) {
	let senderEnTitle = "Customer Information";
	let senderArTitle = "معلومات الزبون";

	return (
		<tr>
			<td width='100%' className='p-0'>
				<h3>
					<span className='w-50'>{senderEnTitle}</span>
					<span className='w-50 text-right'>{senderArTitle}</span>
				</h3>
				<p>
					<span className='w-25 text-start'>Name:</span>
					<span className='w-50 text-center'>
						<b> {props.data.personName}</b>
					</span>
					<span className='w-25 text-end'>:الاسم</span>
				</p>
				<p>
					<span className='w-25 text-start'>Phone:</span>
					<span className='w-50 text-center'>
						<b> {props.data.personPhone} </b>
					</span>
					<span className='w-25 text-end'>:هاتف</span>
				</p>
				<p>
					<span className='w-25 text-start'>Email:</span>
					<span className='w-50 text-center'>
						<b> {props.data.personEmail} </b>
					</span>
					<span className='w-25 text-end'>:البريد الإلكتروني</span>
				</p>
				<p>
					<span className='w-25 text-start'>Gift Card No:</span>
					<span className='w-50 text-center'>
						<b> {(props.isBankCopy || props.isReprint) ? props.data.accountNumber : props.data.customerAccount }
						{(!props.isBankCopy && !props.isReprint && props.data.authorizationCode) ? <div style={{ display: 'inline', padding: 0, margin: 0 }}> | {props.data.authorizationCode}</div> : null}		
						</b>		
					</span>
					<span className='w-25 text-end'>:رقم بطاقة الهدية:</span>
				</p>
			</td>
		</tr>
	);
}
function FinancialInfo(props) {
	return (
		<table width='100%' cellPadding='0' cellSpacing='0'>
			<tbody>
				<tr>
					<td width='100%' className='p-0'>
						<h3>
							<span className='w-50'>Financial Info</span>
							<span className='w-50 text-right'>المعلومات المالية</span>
						</h3>
					</td>
				</tr>
				<tr>
					<td width='100%' className='p-0'>
						<table width='100%' cellPadding='5' className='user-detail'>
							<thead>
								<tr>
									<th width='5%'>#</th>
									<th>Currency</th>
									{/* /العملة */}
									<th className='text-right'>
										Amount
										{/* /المحلية */}
									</th>
									<th className='text-right'>
										Total Fee
										{/* /الرسوم */}
									</th>
								</tr>
							</thead>
							<tbody>
								{props.data.currencyExDetailReportResponses.map(
									(item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td className='w-50 text-right'>
												{item.forexCurrencyCode}
											</td>
											<td className='text-right'>
												{FormatAmount(item.forexCurrency)}
											</td>
											<td className='text-right'>
												{FormatAmount(item.serviceFee + item.rounding)}
											</td>
										</tr>
									)
								)}
								<td colSpan="1" style={{padding:"8px 0 0 0"}}>
									<GenerateQRCode data={props.data.qrCode} size={70}/>
									<p className="text-center">Scan to track</p>
								</td>
							</tbody>
						</table>
						{/* <table width='100%' className='totals'>
							<tbody>
								{props.data.serviceFeeBreakdownResponses.map((item, index) => (
									<tr key={index}>
										<td></td>
										<td width='40%' className='text-right'>
											{item.feeLabel}
										</td>
										<td width='20%' className='text-right'>
											<span>{item.amountText}</span>
										</td>
									</tr>
								))}
							</tbody>
						</table> */}
					</td>
				</tr>
			</tbody>
		</table>
	);
}
function Comments(props) {
	return (
		props.data.cashierComments ? (
			<table width='100%' cellPadding='0' cellSpacing='0'>
				<tbody>
					<tr>
						<td width='100%' className='p-0'>
							<h3>
								<span className='w-50'>Comments</span>
								<span className='w-50 text-right'>ملاحظات</span>
							</h3>
						</td>
					</tr>
					<tr>
						<td width='100%' className='p-0'>
							<p>{props.data.cashierComments}</p>
						</td>
					</tr>
				</tbody>
			</table>
		) : null
	);
}
function Signature(props) {
	return <div></div>;
}

function FooterInfo(props) {
	return <div></div>;
}

export {
	Header,
	Signature,
	FooterInfo,
	CustomerInfo,
	ClientInfo,
	FinancialInfo,
	Comments,
};
