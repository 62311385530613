import React, { Component } from 'react';
import SendReceipt from '../components/Remittance/SendReceipt';
import { getRemittanceReportData } from '../services/ReportService';

export default class SendRemittanceReceipt extends Component {

  constructor(){
    super();
    this.language = 'en';
    var count = window.location.pathname.split('/').length; 
    this.tid = window.location.pathname.split('/')[2];
    this.isReprintData = false;
    this.showSignature = false;

    var queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get('v') == 1) {
      this.isReprintData = true;
    }

    if(queryParams.get('s') == 1) {
      this.showSignature = true;
    }

    this.state = { reportData: null };
  }
  componentDidMount() {

    getRemittanceReportData(this.tid, this.language).then((res) => {
      this.setState({reportData : res.data});
    });
  }
  render() {
    return (
      this.state.reportData ?
        <div>
          <SendReceipt result={this.state.reportData} isBankCopy={false} isReprint={this.isReprintData} showSignature={this.showSignature}/>
          {/* <div style={{height:"10px"}}></div> */}
          <SendReceipt result={this.state.reportData} isBankCopy={true} isReprint={this.isReprintData} showSignature={this.showSignature}/>
        </div>
        : <div></div>
    );
  }
}
